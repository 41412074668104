img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 90%;
}

.partImage {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 90%;
    margin: 20px auto;
  }

  .headerAndSearch {
    min-width: 313px;
  }

  .logoDiv {

  }